@mixin ins-accordion {
  $border-color: #eeeeee;

  .ins-accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: .4s;

    &-head {
      display: flex;
      padding: 7px 15px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-color;
      background-color: #fff;
      cursor: pointer;
    }

    &-collapse {
      display: none;
    }

    &-toggle {
      cursor: pointer;

      .la {
        transition: .4s;
      }

      &.show {
        .la-angle-right {
          transition: .4s;
          transform: rotate(90deg);
        }
      }
    }

    &-body {
      overflow: hidden;
      padding: 20px 20px 0 20px;
      background-color: rgba(196, 196, 196, .05);
      width: 100%;
    }
  }
}

@include ins-accordion()
