@import "~src/variables";

.btn-square {
  min-width: 40px;
  min-height: 40px;
  border: $--new-button-border-color 1px solid;
}

.btn-secondary {
  height: 40px;
  color: $primary-color;
  border: solid 1px #dbe0eb;
  background-color: #ffffff;

  i {
    color: $secondary-color;
  }

  &:hover {
    color: white;
    border-color: #dbe0eb;
    background-color: $primary-color;
  }
}

.btn-primary:disabled {
  cursor: default;
}
