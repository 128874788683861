@import "~src/variables";

.modal {

  &-header {
    align-items: center;
    padding: 15px 20px;
  }

  &-title {
    font-size: 18px;
    letter-spacing: 0.09px;
  }

  &-body {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.4px;
    display: flex;
    flex: 1;
  }

  .close {
    font-size: 20px;
    padding: 0 1rem;

    &:hover {
      color: $secondary-color;
    }
  }

  &-backdrop {
    opacity: 0.30;
    background-color: #616a7a;
  }

  &-content {
    border: none;
    box-shadow: 0 0 10px 0 $box-shadow-color;
  }

  &-footer {
    justify-content: space-between;
  }
}
