.subheader {
  margin-bottom: 20px;
  display: flex;
  align-items: stretch;

  &__main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
  }

  &__title {
    margin: 0;
    padding: 0 1.5rem 0 0;
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    list-style-type: none;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    &-seperator::after {
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      content: " ";
      background: rgba(149, 156, 182, 0.5);
    }

    &-link {
      padding: 0 0.35rem 0 0;
      margin: 0;
      font-size: .9rem;
    }
  }

  &__toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;

    &-item {
      padding-right: 10px;
    }
  }
}
