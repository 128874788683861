@use 'sass:math';
@import '~src/variables';

@mixin switch-base() {
  .controls {
    display: inline-block;

    &.loading {
      & .switch-loader {
        display: block;
      }

      & .switch--icon {
        input:empty ~ span:before,
        input:empty ~ span:after {
          content: ' ';
        }

        & input:empty ~ span > .loader-icon:before {
          display: inline-block;
          width: 0.9rem;
          height: 0.9rem;
          vertical-align: -0.125em;
          border: 0.15em solid currentColor;
          border-right-color: transparent;
          border-radius: 50%;
          -webkit-animation: 0.75s linear infinite spinner-border;
          animation: 0.75s linear infinite spinner-border;
          content: ' ';
          z-index: 10;
          top: 8px;
          left: 7px;
        }
      }
    }
  }

  .switch-control {
    display: flex;
    margin: 5px 0;
    align-items: center;
  }

  .switch-loader {
    display: none;

    & .small-loader {
      margin: 0;

      & .spinner-border {
        $loader-size: 1rem;
        width: $loader-size;
        height: $loader-size;
        border-width: 0.15rem;
      }
    }
  }

  .switch {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    margin-right: 1rem;

    .custom-icon {
      font-size: 18px;
      position: absolute;
      z-index: 5;
      left: 10px;
      transition: 0.2s all linear;
      font-family: $--font-roboto;
      &.active {
        transition: 0.2s all linear;
        left: initial;
        right: 12px;
        color: #fff;
      }
    }
    &.standalone {
      margin-right: 0;
    }

    label {
      margin: unset;
    }

    input:empty {
      margin-left: -999px;
      height: 0;
      width: 0;
      overflow: hidden;
      position: absolute;
      opacity: 0;
    }

    input:empty ~ span {
      display: inline-block;
      position: relative;
      float: left;
      width: 1px;
      text-indent: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    input:empty ~ span {
      &:before,
      &:after,
      & > .loader-icon:before,
      & > .loader-icon:after {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        content: ' ';
        -webkit-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
      }
    }

    // State icons
    &.switch--icon {
      input:empty ~ span:not(.with-custom-icon):after {
        content: '\f00d';
      }

      input:checked ~ span:not(.with-custom-icon):after {
        content: '\f00c';
      }
    }

    // Default size
    & {
      $switch-margin: 0;
      $switch-width: 54px;
      $switch-height: 30px;
      $switch-line-height: 26px;
      $switch-space: 3px;
      $switch-checked-tick-pos: 26px;
      $switch-icon-font-size: 0.9em;

      @include switch-size(
        $switch-margin,
        $switch-width,
        $switch-height,
        $switch-line-height,
        $switch-space,
        $switch-checked-tick-pos,
        $switch-icon-font-size
      );
    }

    // Form group
    .form-group.row & {
      margin-top: 0.15rem;

      &.switch--lg {
        margin-top: 0rem;
        position: relative;
        top: -0.3rem;
      }

      &.switch--sm {
        margin-top: 0.3rem;
      }
    }
  }
}

// Component Size
@mixin switch-size($margin, $width, $height, $line-height, $space, $checked-tick-pos, $icon-font-size) {
  input:empty ~ span {
    line-height: $height;
    margin: $margin;
    height: $height;
    width: $width + $space;
    border-radius: math.div($height, 2);
  }

  input:empty ~ span:before,
  input:empty ~ span:after {
    width: $width;
    border-radius: math.div($height, 2);
  }

  input:empty ~ span:after {
    height: $height - 2 * $space;
    width: $height - 2 * $space;
    line-height: $line-height;
    top: $space;
    bottom: $space;
    font-size: $icon-font-size;
    margin-left: $space;
    text-align: center;
    vertical-align: middle;
  }

  &.switch--checked:not(.switch--loading),
  &.switch--loading.switch--checked {
    input ~ span:after {
      margin-left: $checked-tick-pos;
    }

    input ~ span > .loader-icon:before {
      margin-left: ($checked-tick-pos - 2px);
    }
  }
}

// Component Skin
@mixin switch-skin() {
  $base-color: #e8ebf1;

  .switch {
    input:empty ~ span:before {
      background-color: $base-color;
    }

    // Tick bg
    input:empty ~ span > .loader-icon:before {
      color: #929292;
    }

    // Tick bg
    &:not(.switch--checked) {
      input:empty ~ span:after {
        color: #929292;
        background-color: rgb(245, 245, 245);
      }
    }

    &:not(.switch--loading),
    &.switch--loading.switch--checked {
      // Checked state
      // Panel bg
      input {
        ~ span:before {
          background-color: $base-color;
          box-shadow: 0px 3px 20px 0px rgba($base-color, 0.41);
        }

        ~ span > .loader-icon:before {
          color: #fff;
        }

        // Tick bg
        ~ span:after {
          background-color: $primary-color;
          color: #fff;
          box-shadow: 0px 3px 20px 0px rgba($base-color, 0.41);
        }
      }
    }

    input[disabled] {
      cursor: not-allowed;

      ~ span:after,
      ~ span:before {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
  }
}

// Component Base
@include switch-base();

// Component Skin
@include switch-skin();
