@import "../variables";

@mixin ins-logs {
  .log {
    width: 100%;

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__item {
      border-bottom: 1px solid #e4e7ec;
      padding: 13px 20px;
    }
  }
}

@include ins-logs();
