
.wizard-content {
  max-width: 330px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  &__text {
    font-size: 14px;
    font-weight: 300;
  }
}

.wizard-body {
  display: block;
  width: 50%;
  padding-left: 0;
  padding-right: 0;
}
