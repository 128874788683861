@import "~src/variables";

.gjs-one-bg {
  background-color: $color-white;
}

.gjs-two-color {
  color: $--color-black;
}

.gjs-three-bg {
  background-color: $color-white;
  color: $--color-black;
}

.gjs-four-color,
.gjs-four-color-h:hover {
  color: $secondary-color;
}

.gjs-blocks-c {
  padding: 10px;

  & .gjs-block {
    margin: 10px;
    flex: 1 1 40%;
    border-radius: 5px;
  }
}

.gjs-editor {
  font-family: $--font-roboto;
}

.gjs-category-title, .gjs-layer-title, .gjs-block-category .gjs-title, .gjs-sm-sector-title {
  background-color: unset;
}

.gjs-pn-btn {
  &.gjs-pn-active {
    background-color: unset;
    box-shadow: unset;
  }

  &:hover:before {
    color: $old--color-primary;
  }
}

.gjs-clm-tags-btn {
  border-radius: 5px !important;
}

.gjs-clm-tags, .gjs-sm-properties, .gjs-editor {
  font-size: 14px;
}

.gjs-sm-field.gjs-sm-composite,
.gjs-sm-composite.gjs-clm-select,
.gjs-sm-composite.gjs-clm-field,
.gjs-field,
.gjs-btn-prim,
.gjs-am-file-uploader > form {
  background-color: unset;
  border: solid 1px $--border-color-dark;
  border-radius: 5px;
}

.gjs-am-assets-cont, .gjs-am-assets-header {
  background-color: unset;
  padding: 0 10px;
}

.gjs-sm-field input, .gjs-clm-select input, .gjs-clm-field input, .gjs-sm-field select, .gjs-clm-select select, .gjs-clm-field select, .gjs-sm-icon {
  color: $--color-black;
}

.gjs-rte-action {
  width: 1em;

  svg {
    width: 1em;
  }
}

.gjs-layer {
  .gjs-layer-title {
    padding: 10px 0;

    .gjs-layer-caret {
      margin-top: 2px;
    }

    .gjs-layer-name {
      padding: 7px 0;
    }
  }

  .gjs-layer-vis, .gjs-layer-move, .gjs-layer-count {
    top: 10px;
  }
}

.gjs-radio-item {
  &:hover {
    background-color: $old--color-primary;

    .gjs-radio-item-label::before {
      color: $color-white;
    }
  }

  .gjs-radio-item-label {
    margin-bottom: 0;
  }
}

#gjs-clm-tags-field {
  align-items: center;

  #gjs-clm-new, #gjs-clm-close, #gjs-clm-checkbox {
    color: $--color-black;
  }
}
