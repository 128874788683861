.loader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin-top: 4rem;

  &__spinner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    margin-bottom: 25px;
  }

  &__image {
    animation: spin 2s linear infinite;
    position: absolute;
  }

  &__rhino {
    display: flex;
    height: 80px;
  }

  &__text {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
