@import "src/_variables.scss";

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &--mb-0 {
    margin-bottom: 0;
  }

  &--row {
    flex-direction: row;
    align-items: center;
  }

  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;

    &.is-bold {
      font-weight: 500;
      font-size: 14px;
    }

    &--row {
      display: flex;
      font-size: 16px;
      margin-left: 10px;
    }
  }

  &__error {
    font-size: .65rem;
    line-height: 2;
    color: #cd4444;
    position: absolute;
  }

  .text-error {
    font-size: .65rem;
    line-height: 1;
    color: #cd4444;
    position: absolute;
  }

  .error-container {
    position: relative;
  }

  &__inline-controls {
    &:first-child {
      margin-top: 0;
    }

    display: inline-flex;
    justify-content: space-between;
    margin-top: 10px;

    .inline-controls__input {
      margin-right: 10px;
    }

    .inline-controls__action {
      width: 45px;
      height: 45px;
    }
  }
}

.btn-outline-secondary {
  overflow: hidden;
  border-color: $form--input-border-color;
  color: $secondary-color;
  max-height: 45px;
  width: 50px;

  &:hover {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: #fff;
  }

  span {
    $span-margin: 10px;
    margin-left: $span-margin;
    margin-right: $span-margin;
  }
}
