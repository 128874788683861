// font
$--font-mon: 'Montserrat', sans-serif;
$--font-roboto: 'Roboto', sans-serif;
// z-index
$--header-z-index: 997;
$--sidebar-z-index: 998;

$font-family: $--font-roboto;
$logo-height: 35px;
$logo-width: 250px;
$navbar-height: 58px;
$footer-height: 60px;
$brand--maincolor--light: #0d73e9;
$warning: #f2b518;
$app--bg: #f9fbfc;

// transition
$--transition-duration: 0.15s;

// colors
$old--color-primary: #0d73e9;
$color-primary-old: $old--color-primary;
$color-white: #fff;
$--color-black: #000;
$--color-gray: #4c4c4c;
$--color-dark-gray: #f4f5f7;
$--color-light-gray: #fcfcfc;
$--color-green: #47c69b;
$--color-red: #d63636;
$app--color-red: #d63636;
$--new-color-red: #e8805c;
$--new-color-green: #58b99d;
$--new-color-yellow: #f2b518;
$--gradient-primary: linear-gradient(90deg, #ffdde5 2.72%, #d7f2fe 99.93%);
$--gradient-primary--light: linear-gradient(90.09deg, #fff2f5 2.72%, #f1fbff 99.93%);
$--gradient-secondary: linear-gradient(90deg, #f07392 0%, #79c7e9 100%);
$gradient-primary: $--gradient-primary;
$primary-color: #2b353a;
$--button-light-grey: #ebedf2;
$secondary-color: #54b5eb;
$background-light: #f1f3f6;
$text-secondary: #c1c1c1;

$selected-active-color: rgba(196, 196, 196, 0.1);

// border
$--border-color: #e4e7ec;
$--border-color-dark: #dbe0eb;
$--border-width: 1px;
$--border-style: solid;
$border-radius: 8px;
$--border-complete: $--border-color $--border-width $--border-style;
$--new-button-border-color: #ebedf2;

// shadow
$box-shadow-color: rgba(69, 65, 78, 0.2);
$box-shadow-color: rgba(7, 28, 61, 0.08);
$box-shadow-base: 0px 0px 19px 0px $box-shadow-color;
$discrete-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

// padding
$--padding: 20px;
$list-padding: 7px 0;

// defaults
$app--padding: 20px;

// border
$app--border-color: #ebedf2;
$app--border-width: 1px;
$app--border-style: solid;
$app--border: $app--border-width $app--border-color $app--border-style;

// form
$form--input-border-color: #ebedf2;
$form--input-border-width: 1px;
$form--input-border-style: solid;

// sidebar
$sidebar--color: #2b353a;
$sidebar--submenu--color: #2b353a;
$sidebar--link-color: white;
$sidebar--link-color-hover: #2b353a;
$sidebar--link-color-hover-bg: linear-gradient(
  90.09deg,
  rgba(255, 221, 229, 0.1) 2.72%,
  rgba(215, 242, 254, 0.1) 99.93%
);
$sidebar--link-color-active-bg: linear-gradient(90.09deg, #ffdde5 2.72%, #d7f2fe 99.93%);
$sidebar--link--dot-color: white;

// user header
$user-color-name: #000000;
$user-color-company: #464646;
$divider-color: #e4e7ec;

// hover color
$hover-color-bg: rgba(0, 0, 0, 0.1);
$hover-color-bg-secondary: rgba(84, 181, 235, 0.3);

%input-border {
  border-color: $form--input-border-color;
  border-width: $form--input-border-width;
  border-style: $form--input-border-style;
}

/*
 Cards
-------------------------- */
$--cards-background-color: #ffffff;
$--cards-margin-bottom: 20px;
$--cards-border-radius: 8px;
$--cards-box-shadow: 0px 0px 19px 0px rgba(7, 28, 61, 0.08);
$--cards-box-shadow--hover: 0 0 10px 0 rgba(90, 144, 207, 0.5);
$--new-cards-box-shadow--hover: 0 0 10px 0 rgba(84, 181, 235, 0.5);
$--cards-padding: 20px;

/*
 Survey builder
-------------------------- */
$--survey-builder-background-color: $color-white;
$--survey-builder-border: $--border-complete;
$--survey-builder-box-shadow: $box-shadow-base;
$--survey-builder-padding: $--padding;
