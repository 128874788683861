@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import "src/_variables.scss";

.ql-toolbar.ql-snow {
  background-color: #f9f9fc;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: $secondary-color;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: $secondary-color;
}
