@import '~bootstrap/scss/progress';
@import "~src/variables";

$ins-progress-bar-height: 6px;
$ins-progress-bar-color: $secondary-color;
$ins-progress-bar-radius: 2.5px;
.progress {
  height: $ins-progress-bar-height;
  color: $ins-progress-bar-color;
  overflow: unset;
  border-radius: $ins-progress-bar-radius;

  &-bar {
    color: $ins-progress-bar-color;
    height: $ins-progress-bar-height;
    border-radius: $ins-progress-bar-radius;
  }

  &-dot {
    height: 10px;
    width: 10px;
    background-color: $ins-progress-bar-color;
    display: block;
    border-radius: 100%;
    z-index: 99;
    margin-left: -2.5px;
    margin-top: -2px;
  }
}
