@import '~src/variables';

@mixin font-roboto-bold($size, $weight: 500) {
  font-family: $--font-roboto;
  font-weight: $weight;
  font-size: $size;
}

@mixin font-roboto-light($size) {
  font-family: $--font-roboto;
  font-weight: 300;
  font-size: $size;
}

@mixin font-roboto-normal($size) {
  font-family: $--font-roboto;
  font-weight: 400;
  font-size: $size;
}

@mixin font-montserrat-bold($size, $weight: 500) {
  font-family: $--font-mon;
  font-weight: $weight;
  font-size: $size;
}

@mixin font-montserrat-light($size) {
  font-family: $--font-mon;
  font-weight: 300;
  font-size: $size;
}

@mixin ellipsis($isFlex: false) {
  @if $isFlex {
    flex: 1;
  }

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
