@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';
@import "src/_variables.scss";

.tippy-content {
  position: relative;
}

.tippy-close {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 9px;
  right: 9px;
  fill: rgb(158, 160, 165);
  cursor: pointer;
  z-index: 1;
}

.tippy-box {
  border-radius: 4px;
  background-color: $primary-color;
  color: #fff;

  .tippy-content {
    padding: 4px 6px;
  }
}

.tippy-box[data-theme~='light'] {
  font-size: 12px;
  word-break: break-word;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
  color: rgb(79, 80, 83);

  & [data-placement^="bottom"] > .tippy-arrow {
    color: $primary-color !important;

    &::before {
      border-bottom-color: $primary-color !important;
    }
  }
}

.tippy-arrow {
  color: $primary-color !important;
}
