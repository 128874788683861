@import "src/_variables.scss";

.no-results {
  color: #000;
  max-width: 330px;

  .la {
    color: $secondary-color;
    font-size: 100px;
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: .3rem;
  }

  &__text {
    font-size: .9rem;
    font-weight: 300;
  }
}

.form-group__error {
  font-size: .65rem;
  line-height: 2;
  color: $--new-color-red;
  position: absolute;
}

.card-columns {
  column-count: 2;
}

.card {
  background-color: unset;
  border: unset;
}

a:hover {
  text-decoration: none;
  color: $primary-color;
}

.form-control:focus {
  box-shadow: none;
  border-color: $secondary-color;
}

// @TODO: Can change
.disabled {
  background-color: #ffffff;
  cursor: default;
  color: rgba(13, 115, 231, 0.3) !important;

  :hover {
    color: inherit;
    background-color: inherit;
  }
}

.alert {
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  i {
    font-size: 33px;
    margin-right: 15px;
    display: flex;
    align-self: center;
  }

  &__text {
    vertical-align: middle;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-self: center;
    flex-grow: 1;
  }

  &.ins-alert-secondary {
    background-color: #b5b5b5;
    color: #fff;
  }
}

.table-portlet {
  margin-bottom: unset;
  font-size: 14px;
}

.fw {
  &-500 {
    font-weight: 500;
  }
}

.portlet-columns {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
}
