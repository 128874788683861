@import "src/_variables.scss";

@mixin ins-dt-filter {
  $main--color: $secondary-color;

  .dt-filter {
    $this: &;

    min-height: 80px;
    width: 100%;
    background-color: rgba(196, 196, 196, .05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: solid 1px #e4e7ec;

    &--column {
      #{ $this }__wrapper {
        flex-direction: column;
      }

      #{ $this }__item {
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        flex-grow: 1;

        &:last-child {
          padding-bottom: unset;
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      app-select {
        width: 200px;
      }

      label {
        font-size: 13px;
        margin-bottom: 5px;
      }
    }

    // todo: rework this scss so it doesn't overwrite bootstrap
    &__actions {
      .btn {
        &:first-child {
          margin-left: 0;
        }

        margin-left: 10px;
      }
    }
  }
}

@include ins-dt-filter();
