@import "~src/variables";

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 20px;
  background-color: #fff;
  border: solid 1px #dbe0eb;
  color: $primary-color;
  width: 40px;
  height: 40px;

  &--rounded {
    border-radius: 100%;
    border-color: $primary-color;
  }

  &--active, &:hover {
    background-color: $primary-color;
    border-color: $primary-color !important;
    color: #fff;
  }

  &--small {
    width: 30px;
    height: 30px;
    font-size: 20px;

    i {
      margin-left: -1px;
      margin-top: -1px;
    }
  }

  &.is-disabled {
    pointer-events: none;
    background-color: #dbe0eb;
    cursor: default;
  }
}

.btn-primary {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.btn-link {
  font-size: 14px;
}

.btn-primary:hover {
  background-color: $primary-color;
  color: #fff;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.dropdown-menu {
  margin-top: 10px;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.12);
  border: none;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

.menuitem-icon {
  display: flex;
  flex-direction: row;

  &.disabled {
    color: #b3b3b3;

    & .menuitem-icon, .dropdown-item {
      cursor: initial;

      &:active {
        color: unset !important;

        .la {
          color: inherit;
        }
      }
    }
  }

  .dropdown-item {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;

    &:hover {
      z-index: 1;
    }

    .la {
      padding-right: 10px;
      font-size: 20px;
      color: $secondary-color;
    }

    &:active {
      color: #fff !important;

      .la {
        color: inherit;
      }
    }
  }
}

