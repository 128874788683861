@import "~src/variables";

.pagination {
  .page {
    &-item {
      margin: 0 4px;
      border-style: solid;
      border-color: white;
      border-width: 1px;
      border-radius: 2px;
      overflow: hidden;
      min-width: 30px;
      height: 30px;

      &.active {
        background-color: $primary-color;

        .page-link {
          color: white;
          background-color: $primary-color;
        }
      }

      &:nth-child(n+3):nth-last-child(n+3) {
        &:not(.active):hover {
          background: white;
          border-color: $--new-button-border-color;
          border-style: solid;

          a {
            background: none;
          }
        }
      }
    }

    &-link {
      border: none;
      color: #000;
      padding: 0.1rem 0.75rem;
    }
  }

  &-first,
  &-prev,
  &-next,
  &-last {
    &.disabled {
      background-color: rgba(240, 243, 255, .5);

      & .page-link {
        background-color: inherit;
      }
    }

    & .page-link {
      background-color: rgba(240, 243, 255, .5);
      color: #000;
    }
  }
}

.pagination-container {
  display: flex;
  align-items: center;

  ins-pagination {
    margin-left: 20px;

    .page-item {
      color: #000;
    }
  }

  p {
    font-size: 13px;
    margin-left: 20px;
    margin-bottom: 0;
  }
}
