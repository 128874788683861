ngneat-dialog {
  .ngneat-dialog-backdrop {
    z-index: 999;
    padding: 20px;
    overflow: auto;
    // backdrop styles
    .ngneat-dialog-content {
      border-radius: 5px;
      margin: auto;
      max-height: unset;

      @keyframes ins-dialog-open {
        0% {
          transform: translateY(-50px);
        }
        100% {
          transform: none;
        }
      }

      animation: ins-dialog-open 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

      .ngneat-drag-marker {
      }
      .ngneat-close-dialog {
      }
      .ngneat-dialog-primary-btn,
      .ngneat-dialog-secondary-btn {
      }
    }
  }
}
