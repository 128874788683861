@import "~src/variables";

.portlet {
  $this: &;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(69, 65, 78, 0.2);
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  &--row {
    flex-direction: row;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    @include make-col-ready();
    @include make-col(3);
    padding: 0;
    border-right: 1px solid $divider-color;
    border-bottom-left-radius: 5px;
    overflow: hidden;

    #{$this}__body {
      background-color: #fcfcfc;
    }
  }

  &__head {
    border-bottom: solid 1px $divider-color;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    min-height: 50px;
    padding: 10px 20px;
    position: relative;

    &-label {
      display: flex;
      align-items: center;
    }

    &-title {
      font-size: 1.1rem;
      font-weight: 500;
      margin-bottom: unset;
    }

    &-toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      position: relative;

      .btn-icon {
        margin-left: 10px;
        width: 28px;
        height: 28px;
        font-size: 16px;
        background-color: transparent;
      }
    }
  }

  &__body {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;

    &--fluid {
      padding: 0;
    }
  }

  &__wrapper {
    padding: 20px;

    &-info {
      width: 100%;
      font-size: 14px;

      .form-group {
        margin-bottom: 1.2rem;
      }

      &--border {
        border-left: solid 1px $divider-color;
      }

      td.fw-bold {
        width: 30%;
      }
    }
  }

  &__image-wrapper {
    padding-right: 20px;

    img {
      border-radius: 100%;
      width: 100px;
      height: 100px;
    }
  }

  &__footer {
    display: flex;
    padding: 10px 20px;
    border-top: 1px solid $divider-color;
    z-index: 1;

    &.is-card {
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: -10px;
    }

    &-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;

      .btn {
        height: 40px;
        font-size: 14px;
      }
    }
  }
}
