@import "src/_variables.scss";

.nav-tabs {
  padding-left: 20px;
}

.nav-link {
  color: #000;
  font-size: 14px;
  padding: 15px 20px;

  i {
    font-size: 20px;
    color: $secondary-color !important;
    vertical-align: middle;
  }

  &:hover,
  &.active {
    color: $secondary-color !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
