// components
$btn-border-radius: 8px;

@import 'variables';
@import './styles/mixins';
@import './styles/bootstrap';
@import './styles/common';
@import './styles/tables';
@import './styles/headers';
@import './styles/portlets';
@import './styles/forms';
@import './styles/buttons';
@import './styles/accordion';
@import './styles/switch-collapse';
@import './styles/switch';
@import './styles/modal';
@import './styles/pagination';
@import './styles/loader';
@import './styles/wizard';
@import './styles/logs';
@import './styles/nav';
@import './styles/ngneat-dialog';
@import './styles/list-filter';
@import './styles/progress';
@import './styles/form-group';
@import './styles/badge';
@import './styles/extends/toastr';
@import './styles/extends/slider';
@import './styles/extends/badge';
@import './styles/extends/helipopper';
@import './styles/extends/quill';
@import './styles/line-awesome/css/line-awesome.min.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import './styles/extends/overlay';
@import 'styles/components/datatable';
@import '~jsoneditor/dist/jsoneditor.min.css';
@import '~grapesjs/dist/css/grapes.min.css';
@import './styles/grapesjs-editor';

html,
body,
app-root {
  height: 100%;
}

body {
  font-family: $font-family;
  background-color: $app--bg;
  font-variant: unset;
  font-feature-settings: unset;
}

a {
  text-decoration: none;
}

i {
  font-style: normal;
}

input.ng-dirty.ng-invalid:not(form) {
  border: 1px solid $--new-color-red; /* red */
}

.font-mon {
  font-family: 'Montserrat', sans-serif;
}

button:focus {
  outline: 0 !important;
}

.switch--icon {
  font-family: 'Line Awesome Free';
  font-weight: 900;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-link:hover {
  text-decoration: none;
  color: $secondary-color;
}

.menu-arrow {
  border-color: transparent;
  border-bottom-color: #fff;
  border-style: dashed dashed solid;
  border-width: 0 8.5px 8.5px;
  position: absolute;
  display: none;
  left: 10.5px;
  top: 40.5px;
  z-index: 1001;
  height: 0;
  width: 0;

  &--backdrop {
    border-bottom-color: rgba(0, 0, 0, 0.2);
    top: 39.5px;
  }
}

.divider {
  width: 1px;
  height: 40px;
  margin: 0 1.3rem;
  background-color: $divider-color;
}

.form-control {
  @extend %input-border;
  height: 45px;
  font-size: 13px;
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &--bg {
    background-color: #f9f9fc;
  }

  &--gradient {
    background-image: linear-gradient(to left, #1c99d6, #e53860);
  }
}

.link-style {
  color: $secondary-color;
  cursor: pointer;

  i {
    font-size: 19px;
  }
}

ngneat-dialog {
  .ngneat-dialog-backdrop {
    background-color: rgba(97, 106, 122, 0.5);
  }

  .ngneat-close-dialog {
    color: $primary-color;
    top: 14px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.up {
  z-index: 999 !important;
  position: relative;
}

.ins-overlay-select-panel {
  z-index: 1001;
}

hr {
  width: 100%;
  border-top: #e4e7ec 1px solid;
  margin: 0 0 20px 0;
}

.btn-link {
  text-decoration: none;
}

.table > :not(:first-child) {
  border-top: unset;
}

thead {
  border-bottom: rgb(222, 226, 230) solid 1px;
}

/* === Override ngx-bootstrap datepicker === */

.bs-datepicker {
  box-shadow: $box-shadow-base !important;
  border-radius: $border-radius !important;
  border: $--border-complete;
  overflow: hidden;

  &-container {
    max-width: 308px;
  }

  &-head {
    border-radius: $border-radius $border-radius 0 0;
  }

  &-body {
    border-radius: 0 0 $border-radius $border-radius;
  }

  &-custom-range {
    background: $--color-light-gray;
    border-left: $--border-complete;
  }

  &-predefined-btns {
    button {
      background: $color-white;
      color: $--color-black;
      transition: background $--transition-duration;
      border: $--border-width $--border-style $--border-color-dark;
      margin-bottom: 14px;
      @include font-roboto-light(14px);
      position: relative;
      z-index: 1;

      &:hover {
        background: $primary-color;
        color: $color-white;
      }

      &:nth-child(even) {
        margin-bottom: 6px;
      }
    }
  }

  .btn-primary:hover {
    background: $--gradient-primary;
    color: $--color-black;
  }
}

.theme-blue {
  .bs-datepicker {
    &-head {
      background-color: $secondary-color !important;
    }

    &-body {
      table {
        td {
          span.selected,
          &.selected span,
          span[class*="select-"]:after,
          &[class*="select-"] span:after {
            background-color: $secondary-color !important;
          }

          &.week span {
            color: $secondary-color !important;
          }

          &.active-week span:hover {
            background-color: $secondary-color !important;
            color: $color-white !important;
          }
        }
      }
    }

    &-predefined-btns {
      button {
        &.selected {
          background: $secondary-color;
          border-color: $secondary-color;
          color: $color-white;
        }
      }
    }
  }

  .btn-today-wrapper, .btn-clear-wrapper {
    .btn-success {
      background-color: $secondary-color !important;
      border-color: $secondary-color !important;
      color: $color-white !important;
    }

    .btn-success:not(:disabled):not(.disabled):active {
      background-color: darken($secondary-color, 20%) !important;
      border-color: darken($secondary-color, 20%) !important;
    }

    .btn-success:hover {
      background: $primary-color !important;
      border-color: $primary-color !important;
    }
  }
}

/* === END of Override ngx-bootstrap datepicker === */
