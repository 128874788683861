@import "~src/variables";

.ins-table {

  thead {
    border-top: none;
    border-left: none;
    border-right: none;

    th {
      border: none;
    }

    tr {
      height: 50px;
    }
  }

  &__select {
    width: 30px;
  }

  th:first-child {
    //padding-left:20px;
    //padding-right: 20px;
  }

  th:last-child {
    padding-right: 20px;
    margin-left: 20px;
  }

  td:last-child {
    padding-right: 20px;
    margin-left: 20px;
  }

  tbody {
    font-weight: 300;

    tr, td {
      vertical-align: middle;
      padding: 0 .75rem;
    }

    tr {
      height: 50px;
    }
  }

  &-hover {
    tbody {

      tr:hover {
        background-color: #FAFAFA;
        cursor: pointer;
      }

      .selected {
        background-color: #FAFAFA;
      }
    }
  }

  border-bottom: 1px solid $divider-color;

  .cdk-column-select {
    padding-left: 20px;
    //padding-right: 20px;
    width: 20px;
  }

  .cdk-cell {
    font-size: .9rem;
    text-align: left;
  }

  .text-link {
    font-weight: 400;
    font-size: .9rem;
    color: $secondary-color;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}
